import { useEffect } from "react";
import HealthHeader from "./HealthHeader";
import { useTranslation } from "react-i18next";

export default function Start({ setState }: { setState: Function }) {
	const { t } = useTranslation();

	// useEffect(() => {
	// 	const timeout = setTimeout(() => {
	// 		setState({ state: "screenSaver" });
	// 	}, 60000);
	// 	return () => clearTimeout(timeout);
	// }, [setState]);
	return (
		<>
			<HealthHeader></HealthHeader>
			<div className="container">
				<img
					src="./illustrations/doctor-falling.svg"
					alt=""
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						width: "60%",
						display: "block",
						marginTop: "128px",
						marginBottom: "128px",
					}}
				/>
				<h1>{t("start_title")}</h1>
				<p>{t("start_description")}</p>

				<sdx-button
					label={t("start_cta")}
					onClick={() => setState({ state: "game" })}
				></sdx-button>
			</div>
		</>
	);
}
