import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
	de: {
		translation: {
			back: "Zurück",
			seconds_one: "{{count}} Sekunde",
			seconds_other: "{{count}} Sekunden",
			language: "Deutsch",

			start_title: "Whoops!",
			start_description:
				"Marc ist im Stress. Beim Studieren der Akten ist er auf dem Boden ausgerutscht. Finde die jeweilig zusammengehörigen Akten, um ihm seine Daten vor dem Wind zu retten und gewinne einen Preis.",
			start_cta: "Los geht's",

			won_title: "Vielen Dank!",
			won_text:
				'Du hast Marcs Daten innerhalb von $t(seconds, {"count": {{seconds}} }) Sekunden gerettet. Damit er seine Akten in Zukunft nicht mehr verliert, kann er sie von jetzt an digital mit curaMED einsehen.',
			won_best: "Gratulation, du hast die heutige Bestzeit geschlagen!",
			won_highscore: 'Bestzeit von heute: $t(seconds, {"count": {{seconds}} })',
			won_present:
				"Für deine Hilfe erhältst du einen kleinen Preis für weniger Stress im Praxisalltag.",
			won_qr_title: "Du hast noch nicht genug gewonnen?",
			won_qr_text:
				"Fülle folgendes Formular aus und versuche dein Glück auf den Hauptgewinn.",
			won_qr_path: "./SwisscomHealth-QR-Code-de.png",

			screensaver_title: "Bereit zu gewinnen?",
			screensaver_cta: "Spiel starten",

			lost_title: "Leider nicht geschafft",
			lost_text:
				"Der Wind hat die Akten weggeweht und Marc hat seine Daten verloren.\nVersuche dein Glück erneut, um den Preis abzuholen.",
			lost_cta: "Erneut versuchen",
		},
	},
	fr: {
		translation: {
			back: "Retour",
			seconds_one: "{{count}} seconde",
			seconds_other: "{{count}} secondes",
			language: "Français",

			start_title: "Oups!",
			start_description:
				"Marc a glissé par terre. Trouve les dossiers respectifs et sauve ses données avant qu’ils ne soient emportés par le vent et gagne un prix.",
			start_cta: "C’est parti",

			won_title: "Merci beaucoup.",
			won_text:
				'Tu as sauvé les données de Marc en $t(seconds, {"count": {{seconds}} }). Pour qu’il ne perde plus ses dossiers à l’avenir, il est désormais numérique avec curaMED.',
			won_best: "Félicitations, tu as réalisé le meilleur temps de la journée.",
			won_highscore:
				'Meilleur temps d’aujourd’hui: $t(seconds, {"count": {{seconds}} })',
			won_present: "Tu recevras un petit prix pour ton aide.",
			won_qr_title: "Tu n’as pas encore assez gagné?",
			won_qr_text:
				"Remplis le formulaire suivant et tente ta chance pour gagner le prix principal.",
			won_qr_path: "./SwisscomHealth-QR-Code-fr.png",

			screensaver_title: "Prêt à gagner?",
			screensaver_cta: "Démarrer une partie",

			lost_title: "Malheureusement, tu n’as pas réussi",
			lost_text:
				"Le vent a emporté les dossiers et Marc a perdu ses données.\nTente à nouveau ta chance pour récupérer le prix.",
			lost_cta: "Essayer à nouveau",
		},
	},
};

i18n
	// .use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "de",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
